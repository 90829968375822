import { useEffect, useRef, useState } from "react";
import { connect, ResolveThunks } from "react-redux";
import SongList from "../..";
import { noSleep } from "../../../../var";
import { rootSelectors, RootState } from "../../../../state/root_reducer";
import { actions } from "../../../../state/slices/songs";
import {
  actions as userActions,
  SongFeedbackAndSeed,
} from "../../../../state/slices/user";
import { formatDuration } from "../../../../util";
import { LoadingSpinner } from "../../../loading_spinner";
import SongActionBar from "../../../song_action_bar";
import { getIconForFeedback } from "../../../../pages/manage_likes";
import spotifyLogo from "./spotify-logo-full.png";

interface OwnProps {
  song: Song;
  artUri: string | null;
  spotifyUri: string | null;
  fetchRecommendationsHandler: (
    song: Song,
    search: boolean,
    algo: "v1" | "v2"
  ) => void;
  includeScore: boolean;
}

interface StateProps {
  recommendations: SongRecommendation[];
  songFeedbackByGid: Record<string, SongFeedbackAndSeed> | null;
  isLoggedIn: boolean;
}

interface DispatchProps {
  onFetchSongFeedback: typeof userActions.fetchSongFeedback;
  onUpdateSelectedSongAndFetchArt: typeof actions.updateSelectedSongAndFetchArt;
  onAddFeedback: typeof userActions.addFeedback;
  onAddToPlaylist: typeof actions.addToPlaylist;
}

export type SongListItemDetailProps = OwnProps &
  StateProps &
  ResolveThunks<DispatchProps>;

export function SongListItemDetail(
  props: SongListItemDetailProps
): JSX.Element {
  const refreshRecsInterval = useRef<NodeJS.Timeout | null>(null);
  const [fetching, setFetching] = useState(false);
  const [algo, setAlgo] = useState<"v1" | "v2">("v1");

  useEffect(() => {
    if (!refreshRecsInterval.current && props.recommendations.length > 0) {
      startRecFetcher(algo);
    }

    const toClear = refreshRecsInterval.current;

    return () => {
      if (toClear) {
        console.log("Clearing rec fetcher: " + toClear);

        clearInterval(toClear);

        refreshRecsInterval.current = null;
      }
    };
  }, [refreshRecsInterval.current]);

  useEffect(() => {
    if (props.songFeedbackByGid === null) {
      props.onFetchSongFeedback();
    }
  }, [props.songFeedbackByGid === null]);

  useEffect(() => {
    if (props.recommendations !== null) {
      setFetching(false);
    }
  }, [props.recommendations]);

  function startRecFetcher(algo: "v1" | "v2"): void {
    refreshRecsInterval.current = setInterval(
      () => props.fetchRecommendationsHandler(props.song, false, algo),
      120_000
    );

    console.log("Started fetcher: " + refreshRecsInterval.current);
  }

  function fetchRecs(algo: "v1" | "v2"): void {
    setFetching(true);

    props.fetchRecommendationsHandler(props.song, true, algo);

    setAlgo(algo);
    startRecFetcher(algo);
  }

  function render(): JSX.Element {
    return (
      <div className="pl-2 pt-2 w-full flex flex-col">
        <div style={{ minHeight: 24 }}>
          <div className="flex flex-shrink">
            <span
              onClick={() => props.onUpdateSelectedSongAndFetchArt(null)}
              className="pb-2 text-left font-bold block text-gray-700 text-md cursor-pointer button-small"
            >
              ← Back
            </span>
          </div>
        </div>
        <div
          className="h-full flex flex-col mr-2"
          style={{ overflowY: "hidden" }}
        >
          {renderSongDetails()}
          {renderRecommendationsSection()}
        </div>
      </div>
    );
  }

  function renderSongDetails(): JSX.Element {
    const icon =
      props.songFeedbackByGid?.[props.song.gid] &&
      getIconForFeedback(props.songFeedbackByGid[props.song.gid].feedback);
    return (
      <div
        className="border rounded border-gray-700 flex flex-row"
        style={{ minHeight: 98 }}
      >
        {props.artUri && props.spotifyUri && (
          <a href={props.spotifyUri} target="_blank">
            <img
              className="p-2"
              src={props.artUri}
              width="96"
              height="96"
              alt="Album Art"
            />
          </a>
        )}
        <div className="flex flex-col flex-grow">
          <div className="ml-2 flex flex-row mt-1">
            <span className="text-left block text-gray-700 font-bold text-lg">
              {props.song.name}
            </span>
            {icon && <span className="ml-2">{`(${icon})`}</span>}
          </div>
          <div className="ml-2">
            <span className="text-left block text-gray-700 font-bold text-lg">
              {props.song.artists.map((artist) => artist.name).join(", ")}
            </span>
          </div>
          <div className="ml-2 flex flex-row items-center">
            <span className="text-left block text-gray-700 text-lg">
              {formatDuration(props.song.duration)}
            </span>
            {props.isLoggedIn ? (
              <span className="ml-4 pl-4 border-l-4 border-gray-400">
                <SongActionBar
                  addBorder={false}
                  trackId={props.song.gid}
                  hasFeedback={!!props.songFeedbackByGid?.[props.song.gid]}
                  onAddFeedback={props.onAddFeedback}
                  onPlaySong={() => {
                    noSleep.enable();
                    props.onAddToPlaylist([props.song]);
                  }}
                />
              </span>
            ) : null}
          </div>
        </div>
        {props.isLoggedIn ? (
          <div className="flex flex-col">
            <div className="flex flex-grow justify-center p-6">
              {props.spotifyUri ? (
                <a href={props.spotifyUri} target="_blank">
                  <img
                    src={spotifyLogo}
                    alt="Spotify Logo"
                    width="160"
                    height="36"
                  />
                </a>
              ) : (
                <img
                  src={spotifyLogo}
                  alt="Spotify Logo"
                  width="160"
                  height="36"
                />
              )}
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  function renderRecommendationsSection(): JSX.Element {
    if (props.recommendations.length === 0 && !fetching) {
      return (
        <div>
          <button
            className="button-generic mt-2"
            onClick={() => {
              fetchRecs("v1");
            }}
          >
            Get Recs V1
          </button>
          <button
            className="button-generic mt-2 ml-2"
            onClick={() => {
              fetchRecs("v2");
            }}
          >
            Get Recs V2
          </button>
        </div>
      );
    } else {
      return fetching ? (
        <LoadingSpinner border={false} />
      ) : (
        <div className="flex flex-col pt-4" style={{ overflowY: "hidden" }}>
          <SongList
            songs={props.recommendations.map((sr) => sr.song)}
            maxSongs={500}
            otherdata={
              props.includeScore
                ? props.recommendations.map((sr) => sr.score + "")
                : undefined
            }
            otherdataName={props.includeScore ? "Score" : undefined}
            selectSongHandler={props.onUpdateSelectedSongAndFetchArt}
          />
        </div>
      );
    }
  }

  return render();
}

function mapState(state: RootState, { song }: OwnProps): StateProps {
  return {
    isLoggedIn: state.user.currentUser?.id !== undefined,
    recommendations: rootSelectors.songs.getRecommendations(state, song.gid),
    songFeedbackByGid: rootSelectors.user.getSongFeedbackByGid(state),
  };
}

const mapDispatch: DispatchProps = {
  onUpdateSelectedSongAndFetchArt: actions.updateSelectedSongAndFetchArt,
  onAddFeedback: userActions.addFeedback,
  onAddToPlaylist: actions.addToPlaylist,
  onFetchSongFeedback: userActions.fetchSongFeedback,
};

export default connect(mapState, mapDispatch)(SongListItemDetail);
